export const FEATURE_TOGGLE_TYPE = {
  prd: 'production',
  stg: 'staging',
  dev: 'development',
  demo: 'demo',
} as const

export type Feature = typeof FEATURE_TOGGLE_TYPE[keyof typeof FEATURE_TOGGLE_TYPE]

export const isDevelopment = (feature: Feature) => feature === FEATURE_TOGGLE_TYPE.dev

// デモ環境向けの機能の出しわけのフラグ
// デモ環境向けだとしても開発環境では、機能が全て見える状態であった方が良いため開発環境でも利用できるようにしている
export const isDevelopmentOrDemo = (feature: Feature) =>
  feature === FEATURE_TOGGLE_TYPE.dev || feature === FEATURE_TOGGLE_TYPE.demo

export const isDemo = (feature: Feature) => feature === FEATURE_TOGGLE_TYPE.demo

/**
 * XYSAAS-9194 【ゲストリストアップ】ブックマーク訴求の有効化フラグ
 */
export const isEnableRemindBookmarkForGuestListUp = (feature: Feature) => isDevelopment(feature)

/**
 * XYSAAS-9251 ゲストリストアップのMyHistory2回目訪問時のモーダル表出機能の有効化機能
 */
export const isEnabledMyHistoryHurryUpModal = (feature: Feature) => isDevelopment(feature)

/**
 * XYSAAS-9040 カップルがゲストから共有された画像を削除できるの有効化フラグ
 */
export const isEnabledCoupleDeletePreSharedImage = (feature: Feature) => isDevelopment(feature)

/**
 * XYSAAS-4256 ゲストリストアップ向けのCL画面機能の有効化フラグ
 */
export const isEnabledCLWebForGuestListUp = (feature: Feature) => isDevelopment(feature)
