import {
  CoupleLogCommonProps,
  isSuccess,
  LOG_EVENT_STATUS,
  loggingCoupleCustomEvent,
} from '@/components/common/googleTagManager'
import CheckOnlyAuthentication from '@/components/cs/auth/checkOnlyAuthentication'
import NetworkErrorModal from '@/components/cs/networkErrorModal'
import { HeaderHelpModal } from '@/components/cs/unit/modal/headerHelpModal'
import { useConfig } from '@/context/config'
import { useCoupleUserContext } from '@/context/cs/session'
import { useError } from '@/context/error'
import { useLogout } from '@/data/hooks/cs/useLogout'
import { colors } from '@/styles/colors'
import { cs_colors } from '@/styles/cs/colors'
import { breakpoints, zIndex } from '@/styles/cs/styleConstants'
import { CsTextStyles } from '@/styles/cs/textStyles'
import { CS_EXTERNAL_WEB_URL } from '@/utils/common/externalWebURL'
import { lintIgnoredCss } from '@/utils/common/stylelint'
import { css, SerializedStyles } from '@emotion/react'
import { Fade } from '@mui/material'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'

type HeaderType = {
  addHeaderStyle?: SerializedStyles
}

export default function Header({ addHeaderStyle }: HeaderType) {
  const { user } = useCoupleUserContext()
  const { config } = useConfig()
  const router = useRouter()

  const { partyUUID, invitationUUID } = router.query
  const logContents = {
    cs_userid: user ? user.coupleUUID : null,
    invi_id: invitationUUID,
    party_id: partyUUID,
  } as CoupleLogCommonProps

  const [isMenuDisplay, setIsMenuDisplay] = useState(false)
  const gotoTop = () => {
    if (router.pathname === '/cs') {
      return router.reload()
    }
    // ErrorContextなどの状態破棄のためwindow.location.assignを利用
    return window.location.assign('/cs')
  }
  const gotoPartnerInvitation = () => {
    if (router.pathname === '/cs/partner/invitation') {
      return router.reload()
    }
    // ErrorContextなどの状態破棄のためwindow.location.assignを利用
    return window.location.assign('/cs/partner/invitation')
  }
  const { setError } = useError()
  const [openNetworkErrorModal, setOpenNetworkErrorModal] = useState<boolean>(false)

  const { logout, success, error } = useLogout()

  const [shouldShowHelpModal, setShouldShowHelpModal] = useState(false)

  const openHelpModal = () => {
    loggingCoupleCustomEvent({
      event: 'cs_header_help_icon_tap',
      cs_userid: logContents.cs_userid,
      party_id: logContents.party_id,
    })
    setShouldShowHelpModal(true)
  }

  const hideHelpModal = () => {
    setShouldShowHelpModal(false)
  }

  useEffect(() => {
    if (isMenuDisplay) {
      loggingCoupleCustomEvent({
        event: 'cs_menu_modal',
        cs_userid: logContents.cs_userid,
        party_id: logContents.party_id,
        invi_id: logContents.invi_id,
      })

      // TODO: XYSAAS-8902 将来的にoverscroll-behaviorが使えるようになればjsによるbodyFixed処理はcssに代替できる
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
    } else {
      document.body.style.removeProperty('position')
      document.body.style.removeProperty('width')
    }
  }, [isMenuDisplay])

  useEffect(() => {
    const loggingLogout = (is_success: isSuccess) =>
      loggingCoupleCustomEvent({
        event: 'cs_logout_success',
        cs_userid: logContents.cs_userid,
        party_id: logContents.party_id,
        invi_id: logContents.invi_id,
        is_success,
      })
    if (success) {
      loggingLogout(LOG_EVENT_STATUS.SUCCESS)
      window.location.assign(success.ridLogoutUrl)
    } else if (error) {
      loggingLogout(LOG_EVENT_STATUS.FAILED)
      setError(error.statusCode, error.code)
    }
  }, [success, error])

  return (
    <>
      <header css={[styles.header.layout, addHeaderStyle && addHeaderStyle]}>
        <div
          css={styles.header.logo}
          onClick={() => {
            loggingCoupleCustomEvent({
              event: 'cs_header_top_tap',
              cs_userid: logContents.cs_userid,
            })
            gotoTop()
          }}
        />
        {
          <div css={styles.header.navbarButtonArea}>
            <button css={styles.header.helpButton} onClick={openHelpModal}>
              <Image src="/images/cs/question.svg" width={48} height={48} layout={'fixed'} />
            </button>
            <CheckOnlyAuthentication>
              <div
                css={styles.header.menu}
                onClick={() => {
                  setIsMenuDisplay(!isMenuDisplay)
                }}
              >
                <button
                  css={[styles.header.menuButton, isMenuDisplay && styles.header.menuButtonActive]}
                >
                  <span />
                  <span />
                  <span />
                </button>
              </div>
            </CheckOnlyAuthentication>
          </div>
        }
        <HeaderHelpModal
          menuModalShow={shouldShowHelpModal}
          hideMenuModal={hideHelpModal}
          logContents={{
            cs_userid: logContents.cs_userid,
            party_id: logContents.party_id,
          }}
        />
      </header>
      <Fade in={isMenuDisplay} timeout={400}>
        <div css={styles.menu.screen}>
          <div css={styles.menu.overlay} onClick={() => setIsMenuDisplay(false)} />
          <nav css={styles.menu.layout}>
            <ul css={styles.menu.ul}>
              <li
                css={[
                  styles.menu.list,
                  CsTextStyles.heading.bold.s14px.h100(cs_colors.textAndIcon.black_text),
                ]}
                onClick={() => {
                  loggingCoupleCustomEvent({
                    event: 'cs_menu_top_tap',
                    cs_userid: logContents.cs_userid,
                    party_id: logContents.party_id,
                    invi_id: logContents.invi_id,
                  })
                  gotoTop()
                }}
              >
                トップ
              </li>
              <li
                css={[
                  styles.menu.list,
                  styles.menu.newWindow,
                  CsTextStyles.heading.bold.s14px.h100(cs_colors.textAndIcon.black_text),
                ]}
                onClick={() => {
                  loggingCoupleCustomEvent({
                    event: 'cs_menu_guide_tap',
                    cs_userid: logContents.cs_userid,
                    party_id: logContents.party_id,
                    invi_id: logContents.invi_id,
                  })
                  window.open(CS_EXTERNAL_WEB_URL(config).USER_GUIDE, '_blank')
                }}
              >
                使い方ガイド
              </li>
              <li
                css={[
                  styles.menu.list,
                  styles.menu.newWindow,
                  CsTextStyles.heading.bold.s14px.h100(cs_colors.textAndIcon.black_text),
                ]}
                onClick={() => {
                  loggingCoupleCustomEvent({
                    event: 'cs_menu_faq_tap',
                    cs_userid: logContents.cs_userid,
                    party_id: logContents.party_id,
                    invi_id: logContents.invi_id,
                  })
                  window.open(CS_EXTERNAL_WEB_URL(config).FAQ, '_blank')
                }}
              >
                よくある質問
              </li>
              <li
                css={[
                  styles.menu.list,
                  CsTextStyles.heading.bold.s14px.h100(cs_colors.textAndIcon.black_text),
                ]}
                onClick={() => {
                  loggingCoupleCustomEvent({
                    event: 'cs_menu_partner_invitation_tap',
                    cs_userid: logContents.cs_userid,
                    party_id: logContents.party_id,
                    invi_id: logContents.invi_id,
                  })
                  gotoPartnerInvitation()
                }}
              >
                パートナー招待
              </li>
            </ul>
            <span
              css={[
                styles.menu.list,
                styles.menu.logout,
                CsTextStyles.heading.bold.s14px.h100(cs_colors.textAndIcon.black_text),
              ]}
              onClick={logout}
            >
              ログアウト
            </span>
          </nav>
          <NetworkErrorModal open={openNetworkErrorModal} setOpen={setOpenNetworkErrorModal} />
        </div>
      </Fade>
    </>
  )
}

const styles = {
  header: {
    layout: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 62px;
      padding: 0 40px;

      @media (max-width: ${breakpoints.sp}px) {
        width: 100%;
        height: 65px;
        padding: 0 16px;
        border-bottom: 1px solid rgba(60, 60, 67, 0.1);
      }
    `,
    navbarButtonArea: css`
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
    `,
    helpButton: css`
      display: flex;
      align-items: center;
      padding: 0;
      text-decoration-line: none;
      background: transparent;
      -webkit-tap-highlight-color: transparent;
      &:hover {
        cursor: pointer;
      }
    `,
    logo: css`
      width: 151px;
      height: 36px;
      cursor: pointer;
      background: url('/images/header-logo-pc.svg') no-repeat;
      background-size: contain;

      @media (max-width: ${breakpoints.sp}px) {
        width: 66px;
        height: 30px;
        background-image: url('/images/header-logo-sp.svg');
      }
    `,
    menu: css`
      position: relative;
      z-index: ${zIndex.drawer + 1};
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      backdrop-filter: blur(20px);
    `,
    menuButton: css`
      position: relative;
      width: 28px;
      height: 17px;
      cursor: pointer;
      background: none;
      span {
        position: absolute;
        left: 0;
        height: 3px;
        background-color: ${colors.COLOR_EA6077};
        border-radius: 4px;
        transition: all 0.4s;
      }
      span:nth-of-type(1) {
        top: 0;
        width: 100%;
      }
      span:nth-of-type(2) {
        top: 7px;
        width: 100%;
      }
      span:nth-of-type(3) {
        bottom: 0;
        width: 100%;
      }
    `,
    menuButtonActive: css`
      span:nth-of-type(1) {
        transform: translateY(7px) rotate(-45deg);
      }
      span:nth-of-type(2) {
        opacity: 0;
      }
      span:nth-of-type(3) {
        width: 100%;
        transform: translateY(-7px) rotate(45deg);
      }
    `,
  },
  menu: {
    screen: [
      css`
        position: fixed;
        top: 0;
        left: 0;
        z-index: ${zIndex.drawer};
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        overflow-y: auto;
      `,
      lintIgnoredCss` /* 自動ソートの無効化のため */
        /* やりたいことはblur(40px)だが、win10の一部端末などでは縁のblurが弱くなる事象があるため、ぼかし用svgを当てる対応をとる */
        /* see: https://digipress.info/tech/svg-blur-for-css-filter/ */
        backdrop-filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='flt-a9qhp9hi' x='0' y='0' width='1' height='1' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='40' result='flt-a9qhp9hi-2'/%3E%3CfeMorphology operator='dilate' radius='40'/%3E%3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='flt-a9qhp9hi-2'/%3E%3C/feMerge%3E%3C/filter%3E%3C/svg%3E#flt-a9qhp9hi");
        /* safariではsvgによるぼかしが効かないため、そのままblur(40px)を適用する */
        -webkit-backdrop-filter: blur(40px);
      `,
    ],
    overlay: css`
      flex: 1;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
    `,
    layout: css`
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      width: 503px;
      height: 100%;
      padding: 115px 80px 74px;
      overflow-y: auto;
      color: ${colors.COLOR_4B4B4B};
      letter-spacing: 0.5px;
      background-color: rgba(255, 255, 255, 0.5);

      @media (max-width: ${breakpoints.sp}px) {
        width: 100%;
        padding: 89px 32px 0;
      }
    `,
    ul: css`
      flex: 1;
    `,
    list: css`
      display: flex;
      align-items: center;
      min-height: 64px;
      padding-left: 24px;
      margin: 16px 8px;
      cursor: pointer;
      border-radius: 8px;
      &:hover {
        background: rgba(234, 96, 119, 0.05);
      }

      @media (max-width: ${breakpoints.sp}px) {
        padding-left: 0;
        margin: 0;
        border-bottom: 1px solid rgba(60, 60, 67, 0.06);
        border-radius: 0;
        &:hover {
          background: none;
        }
      }
    `,
    newWindow: css`
      &::after {
        width: 12px;
        height: 12px;
        margin-left: 8px;
        content: '';
        background: url('/images/cs/new-window.svg') no-repeat;
        background-size: contain;
      }
    `,
    logout: css`
      border-bottom: 0 !important;
      @media screen and (orientation: landscape) {
        margin-top: 64px;
      }
    `,
  },
}
