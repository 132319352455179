import {
  CoupleLogCommonProps,
  loggingCoupleCustomEvent,
} from '@/components/common/googleTagManager'
import { CustomLink } from '@/components/cs/input'
import { useCoupleUserContext } from '@/context/cs/session'
import { colors } from '@/styles/colors'
import { zIndex } from '@/styles/cs/styleConstants'
import { css } from '@emotion/react'
import Image from 'next/legacy/image'
import { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

export type CreateInvitationHeaderProps = {
  skipHref?: LinkProps['href']
  skipAs?: LinkProps['as']
  skipEventName?: string
}

export default function CreateInvitationHeader({
  skipHref,
  skipAs,
  skipEventName,
}: CreateInvitationHeaderProps) {
  const { user } = useCoupleUserContext()
  const router = useRouter()
  const { partyUUID, invitationUUID } = router.query
  const logContents = {
    cs_userid: user.coupleUUID,
    invi_id: invitationUUID,
    party_id: partyUUID,
  } as CoupleLogCommonProps

  return (
    <header css={styles.header}>
      <div css={styles.headerInner}>
        <div css={styles.leftAccessory}>
          <Image
            src="/images/cs/icons/22px/back.svg"
            width={22}
            height={22}
            layout={'fixed'}
            onClick={() => router.back()}
          />
        </div>
        <div css={styles.rightAccessory}>
          {!!skipHref && (
            <CustomLink
              href={skipHref}
              as={skipAs}
              onClick={() =>
                loggingCoupleCustomEvent({
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
                  event: skipEventName,
                  cs_userid: logContents.cs_userid,
                  party_id: logContents.party_id,
                  invi_id: logContents.invi_id,
                })
              }
            >
              あとで決める
            </CustomLink>
          )}
        </div>
      </div>
    </header>
  )
}

const styles = {
  header: css`
    position: sticky;
    top: 0px;
    z-index: ${zIndex.appBar};
    width: 100%;
    min-height: 56px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(20px); /* TODO: Firefox, IEの対応 */
  `,
  headerInner: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 816px;
    min-height: 56px;
    margin: 0 auto;
  `,
  leftAccessory: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px;
  `,
  rightAccessory: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px;
    a {
      font-size: 12px;
      font-style: normal;
      font-weight: bold;
      line-height: 100%;
      color: ${colors.COLOR_EA6077};
      text-decoration: none;
    }
  `,
}
