import { useError } from '@/context/error'
import { useLogout } from '@/data/hooks/entry/useLogout'
import { css } from '@emotion/react'
import Image from 'next/legacy/image'
import { useEffect, useState } from 'react'

import { EntryCloseModal } from './entryCloseModal'

export default function Header({ showHeaderContents = true, showEntryCloseModalOnLogout = true }) {
  const [open, setOpen] = useState<boolean>(false)
  const { logout, success, error } = useLogout()
  const { setError } = useError()

  useEffect(() => {
    if (success) {
      /**
       * router.push('/entry')で遷移した場合は画面更新が発生せず、エラー表示のコンポーネントが残ったままとなる
       * router.reload()で画面更新を行えばコンポーネントの状態をリセット出来るが、パスの指定が出来ないので、
       * 共通ヘッダーのログアウトボタンで使用する場合は一度 '/entry' にパスを変更してから画面更新を行う必要がある
       * 上記の手順は window.location を使用すれば画面の更新と遷移が同時に行えるので、useRouter ではなく window.location でトップに戻る対応をしている
       */
      window.location.href = '/entry'
    } else if (error) {
      setError(error.statusCode, error.code)
    }
  }, [success, error])

  return (
    <header css={styles.header}>
      <Image src={'/images/header-logo-pc.svg'} width={125} height={30} />
      {showHeaderContents && (
        <>
          <div css={styles.text}>ゼクシィオンライン招待状 申込フォーム</div>
          <div
            css={styles.logout}
            onClick={showEntryCloseModalOnLogout ? () => setOpen(true) : () => logout()}
          >
            ログアウト
          </div>
          <EntryCloseModal open={open} setOpen={setOpen} />
        </>
      )}
    </header>
  )
}

const styles = {
  header: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 46px;
    padding: 8px 32px;
    background-color: #ffffff;
    border-bottom: 1px solid #d8d8d8;
  `,
  text: css`
    font-size: 16px;
    font-weight: 600;
    color: #595959;
  `,
  logout: css`
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 300;
    color: #595959;
    cursor: pointer;
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 1px;
      height: 16px;
      margin-top: -8px;
      content: '';
      background-color: #cdcdcd;
    }
  `,
}
