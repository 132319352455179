import Logo from '@/components/guest/images/logo'
import { calcSp, mqSp } from '@/styles/guest/styleConstants'
import { GUEST_EXTERNAL_WEB_URL } from '@/utils/common/externalWebURL'
import { css } from '@emotion/react'
import Image from 'next/legacy/image'

interface FooterProps {
  pattern: 'white' | 'black'
}
const patterns = {
  black: {
    colors: {
      text: 'rgba(255, 255, 255, 0.7);',
      background: 'rgba(0, 0, 0, 0.3);',
      border: 'rgba(255, 255, 255, 0.1)',
      recruit: '#FFFFFF',
    },
    logoColor: '#fff',
    recruitImgPath: '/images/recruit-logo-white.png',
  },
  white: {
    colors: {
      text: '#4B4B4B',
      background: '#F7F8FA',
      border: 'rgba(60, 60, 67, 0.1)',
      recruit: '#435B67',
    },
    logoColor: '#EA6077',
    recruitImgPath: '/images/recruit-logo.png',
  },
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
const getStyles = ({ text, background, border, recruit }) => ({
  footer: css`
    width: 100%;
    padding: 25px 40px;
    font-family: 'Hiragino Kaku Gothic ProN', 'メイリオ', sans-serif;
    text-align: left;
    background: ${background};
    ${mqSp()} {
      padding: ${calcSp(26)} ${calcSp(24)};
    }
  `,
  container: css`
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid ${border};
    ${mqSp()} {
      display: block;
      padding-bottom: ${calcSp(24)};
    }
  `,
  logo: css`
    width: 151px;
    height: 18px;
    ${mqSp()} {
      width: ${calcSp(151)};
      height: ${calcSp(18)};
    }
  `,
  links: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -12px 0 0;
    ${mqSp()} {
      margin: 0 0 0 ${calcSp(-12)};
    }
  `,
  link: css`
    padding: 0 12px;
    font-size: 11px;
    line-height: 1;
    color: ${text};
    text-decoration-line: none;
    letter-spacing: 0.5px;
    -webkit-tap-highlight-color: transparent;
    ${mqSp()} {
      padding: 0 ${calcSp(12)};
      margin-top: ${calcSp(12)};
      font-size: ${calcSp(11)};
      letter-spacing: ${calcSp(0.5)};
    }
  `,
  linkSideBorderPcSp: css`
    border-right: 1px solid ${border};
  `,
  linkSideBorderPc: css`
    border-right: 1px solid ${border};
    ${mqSp()} {
      border-right: none;
    }
  `,
  recruit: css`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: 24px;
    color: ${text};
    text-decoration-line: none;
    -webkit-tap-highlight-color: transparent;
    ${mqSp()} {
      margin-top: ${calcSp(24)};
    }
  `,
  recruitImg: css`
    width: 100px;
    height: 26px;
    ${mqSp()} {
      width: ${calcSp(100)};
      height: ${calcSp(26)};
    }
  `,
  copyright: css`
    padding-left: 10px;
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    color: ${recruit};
    text-align: left;
    letter-spacing: 0.5px;
    ${mqSp()} {
      padding-left: ${calcSp(10)};
      font-size: ${calcSp(10)};
      letter-spacing: ${calcSp(0.5)};
    }
  `,
})
export default function Footer({ pattern }: FooterProps) {
  const { colors, logoColor, recruitImgPath } = patterns[pattern]
  const styles = getStyles(colors)
  return (
    <footer css={styles.footer}>
      <div css={styles.container}>
        <Logo color={logoColor} css={styles.logo} />
        <div css={styles.links}>
          <a
            href={GUEST_EXTERNAL_WEB_URL.FAQ}
            css={[styles.link, styles.linkSideBorderPcSp]}
            target="_blank"
            rel="noreferrer"
          >
            よくある質問・お問い合わせ
          </a>
          <a
            href={GUEST_EXTERNAL_WEB_URL.TERMS_URL}
            css={[styles.link, styles.linkSideBorderPc]}
            target="_blank"
            rel="noreferrer"
          >
            利用規約
          </a>
          <a
            href={GUEST_EXTERNAL_WEB_URL.PRIVACY_POLICY}
            css={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            プライバシーポリシー
          </a>
        </div>
      </div>
      <a css={styles.recruit} href="https://www.recruit.co.jp/" target="_blank" rel="noreferrer">
        <div css={styles.recruitImg}>
          <Image src={recruitImgPath} alt="recruit" width={100} height={26} layout="responsive" />
        </div>
        <div css={styles.copyright}>(C) Recruit Co., Ltd.</div>
      </a>
    </footer>
  )
}
