import { fontStyle } from '@/styles/cs/styleConstants'
import { css } from '@emotion/react'

/** カップルの文字の太さの指定 */
const FONT_WEIGHTS = {
  /*
   * note:
   *   Android 用の Noto sans JP では 700 以降 でないと太字にならないため、CS では BOLD は 700 を利用する。
   * note:
   *   Figma ではツールの仕様上 700 が使えないため、Bold のデザイン が 600 で納品されるが、
   *   開発の際に 700 に読み替えて実装するものとする。
   */
  BOLD: 700,
  MEDIUM: 500,
  REGULAR: 400,
} as const
type FONT_WEIGHT = typeof FONT_WEIGHTS[keyof typeof FONT_WEIGHTS]

type TextStyleBaseProp = {
  size: number
  weight: FONT_WEIGHT
  height: number
  color: string
  space: number
}
const textStyleBase = ({ size, weight, height, space, color }: TextStyleBaseProp) => css`
  font-size: ${size}px;
  font-style: normal;
  font-weight: ${weight};
  line-height: ${height};
  color: ${color};
  letter-spacing: ${space}px;
`

const linkStyle = (textStyleBaseProp: TextStyleBaseProp) => [
  textStyleBase(textStyleBaseProp),
  css`
    text-decoration-line: underline;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  `,
]

const alphanumericStyle = (textStyleBaseProp: TextStyleBaseProp) => [
  textStyleBase(textStyleBaseProp),
  css(fontStyle.alphanumeric),
]

/**
 * カップル画面に用いるテキストのスタイル
 *
 * @note: 利用の際は以下のように用いる
 * ```
 *   <p css={[
 *     styles.someStyle,
 *     CsTextStyles.heading.regular.s24px.h150(cs_colors.textAndIcon.black_text)
 *   ]}>
 *     ...
 *   </p>
 * ```
 */
export const CsTextStyles = {
  /** 見出しに用いるスタイル */
  heading: {
    regular: {
      s24px: {
        h150: (color: string) =>
          textStyleBase({
            size: 24,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 24,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0.5,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 24,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            color,
            space: 0.5,
          }),
      },
      s20px: {
        h150: (color: string) =>
          textStyleBase({
            size: 20,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            space: 0.5,
            color,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 20,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            space: 0.5,
            color,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 20,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1,
            space: 0.5,
            color,
          }),
      },
    },
    bold: {
      s24px: {
        h150: (color: string) =>
          textStyleBase({
            size: 24,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 24,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0.5,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 24,
            weight: FONT_WEIGHTS.BOLD,
            height: 1,
            color,
            space: 0.5,
          }),
      },
      s20px: {
        h150: (color: string) =>
          textStyleBase({
            size: 20,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 20,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0.5,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 20,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0.5,
          }),
      },
      s18px: {
        h150: (color: string) =>
          textStyleBase({
            size: 18,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 18,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0.5,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 18,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0.5,
          }),
      },
      s16px: {
        h150: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0.5,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0.5,
          }),
      },
      s14px: {
        h150: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0.5,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0.5,
          }),
      },
      s12px: {
        h150: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0.5,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0.5,
          }),
      },
    },
  },
  /** 説明文などに用いるスタイル */
  body: {
    regular: {
      s20px: {
        h100: (color: string) =>
          textStyleBase({
            size: 20,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0,
          }),
      },
      s18px: {
        h150: (color: string) =>
          textStyleBase({
            size: 18,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 18,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0,
          }),
      },
      s16px: {
        h150: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h150_link: (color: string) =>
          linkStyle({
            size: 16,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0,
          }),
        h100_link: (color: string) =>
          linkStyle({
            size: 16,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0,
          }),
      },
      s14px: {
        h180: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.8,
            color,
            space: 0,
          }),
        h180_link: (color: string) =>
          linkStyle({
            size: 14,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.8,
            color,
            space: 0,
          }),
        h150: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h150_link: (color: string) =>
          linkStyle({
            size: 14,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0,
          }),
        h100_link: (color: string) =>
          linkStyle({
            size: 14,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0,
          }),
      },
      s12px: {
        h180: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.8,
            color,
            space: 0,
          }),
        h180_link: (color: string) =>
          linkStyle({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.8,
            color,
            space: 0,
          }),
        h150: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h150_link: (color: string) =>
          linkStyle({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0,
          }),
        h120_link: (color: string) =>
          linkStyle({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0,
          }),
        h100_link: (color: string) =>
          linkStyle({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0,
          }),
      },
      s11px: {
        h150: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h150_link: (color: string) =>
          linkStyle({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0,
          }),
        h100_link: (color: string) =>
          linkStyle({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0,
          }),
      },
      s10px: {
        h150: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h150_link: (color: string) =>
          linkStyle({
            size: 10,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0,
          }),
        h100_link: (color: string) =>
          linkStyle({
            size: 10,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0,
          }),
      },
    },
    bold: {
      s20px: {
        h100: (color: string) =>
          textStyleBase({
            size: 20,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0,
          }),
      },
      s18px: {
        h150: (color: string) =>
          textStyleBase({
            size: 18,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 18,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0,
          }),
      },
      s16px: {
        h150: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 16,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0,
          }),
      },
      s14px: {
        h150: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0,
          }),
      },
      s12px: {
        h150: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0,
          }),
      },
      s11px: {
        h150: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0,
          }),
      },
      s10px: {
        h150: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.5,
            color,
            space: 0,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0,
          }),
      },
    },
  },
  /** 説明文や注釈、フォーム周りなどに用いるスタイル */
  bodyWide: {
    regular: {
      s15px: {
        h150: (color: string) =>
          textStyleBase({
            size: 15,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0.5,
          }),
      },
      s14px: {
        h120: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0.5,
          }),
      },
      s12px: {
        h150: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h150_link: (color: string) =>
          linkStyle({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0.5,
          }),
        h120_link: (color: string) =>
          linkStyle({
            size: 12,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0.5,
          }),
      },
      s11px: {
        h150: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h150_link: (color: string) =>
          linkStyle({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0.5,
          }),
        h120_link: (color: string) =>
          linkStyle({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0.5,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0.5,
          }),
        h100_link: (color: string) =>
          linkStyle({
            size: 11,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.0,
            color,
            space: 0.5,
          }),
      },
      s10px: {
        h180_link: (color: string) =>
          linkStyle({
            size: 10,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.8,
            color,
            space: 0.5,
          }),
        h150: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h150_link: (color: string) =>
          linkStyle({
            size: 10,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.5,
            color,
            space: 0.5,
          }),
        h120: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0.5,
          }),
        h120_link: (color: string) =>
          linkStyle({
            size: 10,
            weight: FONT_WEIGHTS.REGULAR,
            height: 1.2,
            color,
            space: 0.5,
          }),
      },
    },
    bold: {
      s14px: {
        h100: (color: string) =>
          textStyleBase({
            size: 14,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0.5,
          }),
      },
      s11px: {
        h120: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0.5,
          }),
        h100: (color: string) =>
          textStyleBase({
            size: 11,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.0,
            color,
            space: 0.5,
          }),
      },
      s10px: {
        h120: (color: string) =>
          textStyleBase({
            size: 10,
            weight: FONT_WEIGHTS.BOLD,
            height: 1.2,
            color,
            space: 0.5,
          }),
      },
    },
  },
  /** 英数字を表示する際に用いるスタイル */
  alphanumeric: {
    regular: {
      s40px: (color: string) =>
        alphanumericStyle({
          size: 40,
          weight: FONT_WEIGHTS.REGULAR,
          height: 1.0,
          color,
          space: 0.5,
        }),
      s32px: (color: string) =>
        alphanumericStyle({
          size: 32,
          weight: FONT_WEIGHTS.REGULAR,
          height: 1.0,
          color,
          space: 0.5,
        }),
      s25px: (color: string) =>
        alphanumericStyle({
          size: 25,
          weight: FONT_WEIGHTS.REGULAR,
          height: 1.0,
          color,
          space: 0.5,
        }),
      s24px: (color: string) =>
        alphanumericStyle({
          size: 24,
          weight: FONT_WEIGHTS.REGULAR,
          height: 1.0,
          color,
          space: 0.5,
        }),
      s20px: (color: string) =>
        alphanumericStyle({
          size: 20,
          weight: FONT_WEIGHTS.REGULAR,
          height: 1.0,
          color,
          space: 0.5,
        }),
      s18px: (color: string) =>
        alphanumericStyle({
          size: 18,
          weight: FONT_WEIGHTS.REGULAR,
          height: 1.0,
          color,
          space: 0.5,
        }),
      s16px: (color: string) =>
        alphanumericStyle({
          size: 16,
          weight: FONT_WEIGHTS.REGULAR,
          height: 1.0,
          color,
          space: 0.5,
        }),
      s14px: (color: string) =>
        alphanumericStyle({
          size: 14,
          weight: FONT_WEIGHTS.REGULAR,
          height: 1.0,
          color,
          space: 0.5,
        }),
      s12px: (color: string) =>
        alphanumericStyle({
          size: 12,
          weight: FONT_WEIGHTS.REGULAR,
          height: 1.0,
          color,
          space: 0.5,
        }),
      s11px: (color: string) =>
        alphanumericStyle({
          size: 11,
          weight: FONT_WEIGHTS.REGULAR,
          height: 1.0,
          color,
          space: 0.5,
        }),
    },
    medium: {
      s24px: (color: string) =>
        alphanumericStyle({
          size: 24,
          weight: FONT_WEIGHTS.MEDIUM,
          height: 1.0,
          color,
          space: 0.5,
        }),
      s14px: (color: string) =>
        alphanumericStyle({
          size: 14,
          weight: FONT_WEIGHTS.MEDIUM,
          height: 1.0,
          color,
          space: 0.5,
        }),
    },
    bold: {
      s25px: (color: string) =>
        alphanumericStyle({
          size: 25,
          weight: FONT_WEIGHTS.BOLD,
          height: 1.0,
          color,
          space: 0.5,
        }),
      s16px: (color: string) =>
        alphanumericStyle({
          size: 16,
          weight: FONT_WEIGHTS.BOLD,
          height: 1.0,
          color,
          space: 0.5,
        }),
    },
  },
}
