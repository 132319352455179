import { useConfig } from '@/context/config'
import { isDevelopment, isEnableRemindBookmarkForGuestListUp } from '@/utils/common/featureToggle'
import Head from 'next/head'

interface Props {
  children?: React.ReactNode
  title: string
}

export const serviceNameForPageTitle = ' | ゼクシィオンライン招待状'
export const guestListUpServiceNameForPageTitle = ' | ゲストリストアップ | ゼクシィオンライン招待状'

// Pageコンポーネントで利用してください（Pageコンポーネント以外での利用厳禁）
const CommonHead = ({ children, title }: Props) => {
  const { config } = useConfig()
  return (
    <Head>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1.0" />
      <meta name="robots" content="noindex" />
      {isEnableRemindBookmarkForGuestListUp(config.featureToggle) && (
        <>
          <link rel="icon" href="/favicon.ico" type="image/x-icon" />
          <link rel="apple-touch-icon" sizes="180x180" href="/touch-icon-180x180-v20250203.png" />
          <link rel="icon" sizes="192x192" href="/touch-icon-192x192-v20250203.png" />
        </>
      )}
      {children}
    </Head>
  )
}

export const GuestHead = ({ children, title }: Props) => {
  const { config } = useConfig()

  return (
    <CommonHead title={title}>
      {/* eslint-disable react/no-danger */}
      {/* @see https://helpx.adobe.com/jp/fonts/using/embed-codes.html */}
      {isDevelopment(config.featureToggle) ? (
        <script
          nonce={config.cspNonce}
          dangerouslySetInnerHTML={{
            __html: `
            (function(d) {
              var config = {
              kitId: 'ymm1tms',
              scriptTimeout: 5000,
              async: true
            },
              h=d.documentElement,t=setTimeout(function(){console.log('setTimeout before ->', h.className);h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";console.log('setTimeout after ->', h.className);},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";
              tk.src='https://use.typekit.net/'+config.kitId+'.js';
              tk.async=true;
              tk.onload=tk.onreadystatechange=function(){
                const classList = [...document.documentElement.classList];
                console.log("onload -- start --", {documentReadyState: document.readyState, tkReadyState: this.readyState, if: f||a&&a!="complete"&&a!="loaded",classList});
                a=this.readyState;
                if(f||a&&a!="complete"&&a!="loaded")return;
                f=true;
                clearTimeout(t);
                try{
                  Typekit.load(config);
                  console.log({ typekit: Typekit.load, config, this: this });
                }catch(e){
                  console.log(e);
                }
                console.log("onload -- done --", {classList: [...document.documentElement.classList]});
              };
              console.log("tk inserted", [...document.querySelectorAll('script')].map(i => i.src));
              s.parentNode.insertBefore(tk,s);
              console.log("tk inserted", [...document.querySelectorAll('script')].map(i => i.src));
              document.addEventListener('readystatechange', (e) => {
                const classList = [...document.documentElement.classList];
                console.log("Bubbling phase", {readyState: e.target.readyState, target: e.target, classList})
              });
              document.addEventListener('readystatechange', (e) => {
                const classList = [...document.documentElement.classList];
                console.log("Capture phase", {readyState: e.target.readyState, target: e.target, classList})
              }, true);  
            })(document);
            `,
          }}
        />
      ) : (
        <script
          nonce={config.cspNonce}
          dangerouslySetInnerHTML={{
            __html: `
            (function(d) {
              var config = {
              kitId: 'ymm1tms',
              scriptTimeout: 3000,
              async: true
            },
              h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
            })(document);
          `,
          }}
        />
      )}
      {/* eslint-enable react/no-danger */}
      <meta property="og:title" content="Wedding Invitation" />
      <meta property="og:description" content="" />
      <meta property="og:image" content={`${config.serviceUrl}/images/guest/ogp.jpg`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={`${config.serviceUrl}/images/guest/ogp.jpg`} />
      {children}
    </CommonHead>
  )
}

export const ClientHead = ({ children, title }: Props) => (
  <CommonHead title={`${title}${serviceNameForPageTitle}`}>{children}</CommonHead>
)

export const CoupleHead = ({ children, title }: Props) => (
  <CommonHead title={`${title}${serviceNameForPageTitle}`}>{children}</CommonHead>
)

export const GuestListUpCoupleHead = ({ children, title }: Props) => (
  <CommonHead title={`${title}${guestListUpServiceNameForPageTitle}`}>{children}</CommonHead>
)

export const EntryHead = ({ children, title }: Props) => (
  <CommonHead title={`${title}${serviceNameForPageTitle}`}>{children}</CommonHead>
)

export const ErrorHead = ({ children }: Pick<Props, 'children'>) => (
  <CommonHead title="エラー">{children}</CommonHead>
)

export const PageNotFoundErrorHead = ({ children }: Pick<Props, 'children'>) => (
  <CommonHead title="お探しのページが見つかりませんでした">{children}</CommonHead>
)

export default CommonHead
