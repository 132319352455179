import { useClientContext } from '@/context/cl/clientContext'
import { useClientUserContext } from '@/context/cl/session'
import { useError } from '@/context/error'
import { useLogout } from '@/data/hooks/cl/useLogout'
import { colors } from '@/styles/colors'
import { css } from '@emotion/react'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { loggingClientCustomEvent } from '../common/googleTagManager'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS7031 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
export default function Header({ logoLink }) {
  const { user } = useClientUserContext()
  const { client } = useClientContext()
  const router = useRouter()
  const { logout, success, error } = useLogout()
  const { setError, statusCode, errorCode } = useError()

  useEffect(() => {
    const logging = (isSuccess: '10' | '20') => {
      loggingClientCustomEvent({
        event: 'cl_logout_success',
        cl_userid: user?.userId,
        cl_cd: client?.clientCd,
        is_success: isSuccess,
      })
    }
    if (success) {
      logging('10')
      router.push('/cl/login')
    } else if (error) {
      logging('20')
      setError(error.statusCode, error.code)
    }
  }, [success, error])

  const handleLogoClick = () => {
    if (logoLink) {
      if (statusCode || errorCode) {
        window.location.href = logoLink
      } else {
        router.push(logoLink)
      }
    }
  }

  return (
    <header css={styles.header}>
      <div css={styles.logo}>
        <Image
          src={'/images/header-logo-pc.svg'}
          className={logoLink && 'link'}
          width={125}
          height={30}
          onClick={handleLogoClick}
        />
      </div>
      {user && (
        <div css={styles.account}>
          <span className="accountIcon">
            <Image src={'/images/cl/icons/account.svg'} width={24} height={24} />
          </span>
          <span className="name">{`${user.lastName} ${user.firstName}`}</span>
          <span
            className="logout"
            onClick={() => {
              logout()
            }}
          >
            ログアウト
          </span>
        </div>
      )}
    </header>
  )
}

const styles = {
  logo: css`
    .link {
      cursor: pointer;
    }
  `,
  header: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 46px;
    padding: 8px 32px;
    background-color: ${colors.COLOR_FFFFFF};
  `,
  account: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    color: ${colors.COLOR_595959};
    .accountIcon {
      margin-right: 6px;
    }
    .name {
      padding-right: 24px;
    }
    .logout {
      position: relative;
      padding-left: 24px;
      cursor: pointer;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 1px;
        height: 16px;
        margin-top: -8px;
        content: '';
        background-color: ${colors.COLOR_CDCDCD};
      }
    }
  `,
}
