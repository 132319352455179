import Link from 'next/link'
import { useRouter } from 'next/router'

type NavLinkType = JSX.IntrinsicElements['a'] & {
  href: string
  children: React.ReactNode
  exact?: boolean
  replace?: boolean
  passHref?: boolean
}

export const NavLink = ({
  href,
  exact = false,
  replace = false,
  passHref = true,
  children,
  ...props
}: NavLinkType) => {
  const { asPath } = useRouter()
  const isActive = exact ? asPath === href : asPath.startsWith(href)

  if (isActive) {
    if (typeof props.className === 'undefined') {
      props.className = 'is-active'
    } else {
      props.className += ' is-active'
    }
  }

  return (
    <Link href={href} passHref={passHref} replace={replace} {...props}>
      {children}
    </Link>
  )
}
